import { useFetch } from '@/api';
import { useBillingHistory } from '@/composables/useBillingHistory';

const { store: billingHistoryStore } = useBillingHistory();

const {
  setInvoicesHistory,
  setDepositsHistory,
  setInvoicesHistoryLoaded,
  setDepositsHistoryLoaded,
} = billingHistoryStore;

export const renewPlan = async () => {
  const { axiosWrapper } = useFetch();

  const { isSuccess } = await axiosWrapper({
    type: 'post',
    url: 'merchants/subscriptions/plans/renew',
  });

  return { isSuccess };
};

export const getDepositOperations = async ({
  year,
  page = 1,
  perPage = 0,
  isFreshResponse = false,
}) => {
  const { axiosWrapper } = useFetch();
  setDepositsHistoryLoaded(false);

  const { isSuccess, data } = await axiosWrapper({
    type: 'get',
    url: '/merchants/deposits/operations',
    params: {
      year, page, perPage, isFreshResponse,
    },
  });

  if (isSuccess) {
    setDepositsHistory(data.deposits);
  }

  setDepositsHistoryLoaded(true);

  return { isSuccess, data };
};

export const getInvoices = async ({
  year,
  page = 1,
  perPage = 0,
  isFreshResponse = false,
}) => {
  const { axiosWrapper } = useFetch();
  setInvoicesHistoryLoaded(false);

  const { isSuccess, data } = await axiosWrapper({
    type: 'get',
    url: '/merchants/invoices',
    params: {
      year, page, perPage, isFreshResponse,
    },
  });

  if (isSuccess) {
    setInvoicesHistory(data.invoices);
  }

  setInvoicesHistoryLoaded(true);

  return { isSuccess };
};

export const getMerchantStats = async (params = { isFreshResponse: false }) => {
  const { axiosWrapper } = useFetch();

  const { isSuccess, data } = await axiosWrapper({
    type: 'get',
    url: '/merchants/stats',
    params,
  });

  return { isSuccess, data };
};
