<template>
  <div class="deposit">
    <QRcode
      type="canvas"
      :data="address"
      :icon="logo"
      size="146px"
      @load="$emit('load')"
    />

    <AppText
      variant="div"
      mt="37px"
      mb="12px"
      class="font-medium"
      opacity="0.4"
    >
      {{ t('merchantBilling.balance.depositAddress') }}
    </AppText>

    <TooltipCopy>
      <transition name="show">
        <AppText
          class="address"
          size="15px"
          is-block
        >
          {{ address }}
        </AppText>
      </transition>
    </TooltipCopy>
  </div>
</template>

<script>
import QRcode from '@/components/QRcode.vue';
import TooltipCopy from '@/components/TooltipCopy.vue';

export default {
  name: 'BalanceQRCode',
  components: {
    QRcode,
    TooltipCopy,
  },
  props: {
    address: {
      type: String,
      required: true,
    },
    logo: {
      type: String,
      required: true,
    },
  },
  emits: ['load'],
};
</script>

<style scoped lang="scss">
.deposit {
  :deep(.code-wrap) {
    margin-top: 37px;
  }

  :deep(.tooltip) {
    width: 280px;
    height: 36px;

    .d-flex {
      align-items: flex-start;
    }
  }

  .address {
    width: 250px;
    word-break: break-all;
  }
}
</style>
