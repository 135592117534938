<template>
  <FDropdown placement="bottom-start">
    <template #title>
      <AppIcon name="options" size="18px" opacity="0.4" />
    </template>

    <template #content>
      <DropdownItem size="small" @click="isModalOpen = true">
        <AppText variant="div" pr="16px">
          {{ t('merchantBilling.balance.label.cancelPlan') }}
        </AppText>
      </DropdownItem>
    </template>
  </FDropdown>

  <ModalToCancelPlan v-model="isModalOpen" :active-subscriptions="activeSubscriptions" />
</template>

<script>
import { ref } from 'vue';

import DropdownItem from '@/components/ElementUI/DropdownItem.vue';

import ModalToCancelPlan from './ModalToCancelPlan.vue';

export default {
  name: 'CancelPlanDropdown',
  components: {
    ModalToCancelPlan,
    DropdownItem,
  },
  props: {
    activeSubscriptions: {
      type: Number,
      default: 0,
    },
  },
  setup() {
    const isModalOpen = ref(false);

    return { isModalOpen };
  },
};
</script>

<style lang="scss" scoped>
:deep(.f-dropdown--title) {
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-black-005);
  border-radius: 4px;
}
</style>
