<template>
  <FButton
    :type="changePlanButtonType"
    size="x-small"
    no-margin
    is-full-width
    @click="isModalOpen = true"
  >
    {{ changePlanButtonText }}
  </FButton>

  <ModalToChangePlan
    v-model="isModalOpen"
    :billing-info="{
      balanceUsd: billingInfo.balanceUsd,
      subscription: billingInfo.subscription,
    }"
  />
</template>

<script>
import { computed, ref } from 'vue';

import ModalToChangePlan from '@/components/Modules/ModalToChangePlan';

import { i18n } from '@/plugins/localization';

export default {
  name: 'ChangePlanButton',
  components: {
    ModalToChangePlan,
  },
  props: {
    billingInfo: {
      type: Object,
      default: () => ({}),
    },
    isPlanCancelled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { t } = i18n.global;
    const isModalOpen = ref(false);

    const currentPlanName = computed(() => (props.billingInfo?.subscription?.plan?.name));
    const changePlanButtonText = computed(() => (currentPlanName.value?.toLowerCase() !== 'free' ? t('merchantBilling.balance.label.changePlan') : t('merchantBilling.balance.label.upgradePlan')));

    const changePlanButtonType = computed(() => {
      if (props.isPlanCancelled) {
        return 'primary';
      }

      return currentPlanName.value?.toLowerCase() === 'black' ? 'black-transparent' : 'secondary';
    });

    return { isModalOpen, changePlanButtonText, changePlanButtonType };
  },
};
</script>
