<template>
  <div class="add">
    <AppText
      variant="div"
      size="22px"
      mt="40px"
      class="font-medium"
    >
      {{ t('merchantBilling.addBalance.title') }}
    </AppText>
    <AppText variant="div" mt="15px" opacity="0.5">
      {{ t('merchantBilling.addBalance.description') }}
    </AppText>

    <div class="deposits">
      <FRadioButton
        v-slot="option"
        v-model="coin"
        :options="coins"
        height="48px"
        is-full-width
      >
        <div class="item">
          <AppIcon :name="option.logo" size="28px" is-img-tag />
          <AppText class="font-medium">
            {{ option.text }}
          </AppText>
        </div>
      </FRadioButton>
      <div class="qr-codes-wrapper" :class="{ 'is-loaded': isLoaded }">
        <BalanceQRCode
          class="qr-code"
          :class="{ 'is-active': coin === 'BTC' }"
          :address="depositAddresses?.btc?.address"
          logo="btc-circle"
          @load="onLoad"
        />
        <BalanceQRCode
          class="qr-code"
          :class="{ 'is-active': coin === 'LTC' }"
          :address="depositAddresses?.ltc?.address"
          logo="ltc-circle"
          @load="onLoad"
        />
      </div>
    </div>

    <div class="footer" />
    <AppText variant="div" opacity="0.5">
      {{ t('merchantBilling.addBalance.footer') }}
    </AppText>
    <AppText variant="div" mt="16px" opacity="0.5">
      {{ t('merchantBilling.addBalance.noRefunds') }}
    </AppText>
  </div>
</template>

<script setup>
import { ref } from 'vue';

import BalanceQRCode from './BalanceQRCode.vue';

defineProps({
  depositAddresses: {
    type: Object,
    default: () => ({}),
  },
});

const coin = ref('BTC');

const coins = [
  {
    text: 'Bitcoin',
    value: 'BTC',
    logo: 'btc-circle',
  },
  {
    text: 'Litecoin',
    value: 'LTC',
    logo: 'ltc-circle',
  },
];

const isLoaded = ref(false);
const onLoad = () => { isLoaded.value = true; };
</script>

<style lang="scss" scoped>
.add {
  width: 360px;
  padding: 0 40px;

  .deposits {
    margin-top: 34px;

    :deep(.el-radio-button__inner) {
      height: 48px;
    }

    .item {
      display: flex;
      align-items: center;
      gap: 12px;
    }
  }

  .footer {
    margin: 40px 0 20px;
    border-top: 1px solid var(--color-black-01);
  }
}

.qr-codes-wrapper {
  position: relative;
  height: 284px; // height limitation
  opacity: 0;
  @include transition-base(opacity);
  &.is-loaded {
    opacity: 1;
  }

  .qr-code {
    position: absolute;
    opacity: 0;
    pointer-events: none;
    @include transition-base(opacity);
    &.is-active {
      pointer-events: initial;
      opacity: 1;
    }
  }
}
</style>
