<template>
  <div class="autorenew">
    <AppIcon v-if="isCancelled" name="lock-grey-thin" size="18px" />

    <AppText
      variant="div"
      size="15px"
      :ml="isCancelled ? '12px' : '0'"
      class="flex-grow-1 font-medium"
    >
      {{ t('merchantBilling.balance.renew.title') }}
    </AppText>

    <FSwitch
      v-model="isAutoRenew"
      class="renew-switch"
      is-small
      type="primary"
      :loading="isLoading"
      :disabled="isCancelled"
      @change="onChange"
    />

    <AppText
      class="info"
      variant="div"
      :line-height="1.35"
      opacity="0.5"
      mt="13px"
    >
      {{ description }}
    </AppText>
  </div>
</template>

<script setup>
import { computed, ref, watch } from 'vue';

import { updateMerchantSettings } from '@/api/merchant';
import { useSettings } from '@/composables/useSettings';
import { i18n } from '@/plugins/localization';

const props = defineProps({
  isCancelled: {
    type: Boolean,
    default: false,
  },
});

const { t } = i18n.global;
const { settings } = useSettings();
const isAutoRenew = ref(settings.value.autoRenewPlan);

const description = computed(() => {
  if (props.isCancelled) {
    return t('merchantBilling.balance.renew.cannotBeEnabled');
  }

  return t('merchantBilling.balance.renew.description');
});

const isLoading = ref(false);
const onChange = async () => {
  isLoading.value = true;
  await updateMerchantSettings({
    autoRenewPlan: isAutoRenew.value,
  });
  isLoading.value = false;
};

watch(() => settings.value.autoRenewPlan, (val) => {
  if (val !== undefined) {
    isAutoRenew.value = val;
  }
});

watch(() => props.isCancelled, (val) => {
  if (val) {
    isAutoRenew.value = false;
  } else {
    isAutoRenew.value = settings.value.autoRenewPlan;
  }
});
</script>

<style lang="scss" scoped>
.autorenew {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  .renew-switch {
    height: 20px;
  }

  .info {
    width: 100%;
  }
}
</style>
