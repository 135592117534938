<template>
  <transition name="show">
    <div v-if="props.isLoaded" class="balance">
      <div class="header">
        <AppText
          variant="div"
          size="12px"
          mt="40px"
          type="uppercase"
          class="font-medium"
          opacity="0.4"
          :line-height="1.3"
        >
          {{ t('merchantBilling.balance.title') }}
        </AppText>

        <NumberFormatter
          :value="props.billingInfo?.balanceUsd"
          size="35px"
          :line-height="1.3"
          is-block
          is-raw
          mt="20px"
          mr="8px"
        />

        <AppText size="35px" opacity="0.4">
          {{ 'USD' }}
        </AppText>

        <AppText
          variant="div"
          size="12px"
          :line-height="1.3"
          opacity="0.4"
          mt="20px"
          mb="25px"
        >
          {{ t('merchantBilling.balance.description') }}
        </AppText>
      </div>

      <NotifyBlock
        v-if="isPlanCancelled"
        :active-subscriptions="props.activeSubscriptions"
        :days-left="daysLeft"
      >
        <StopCancellationPlanButton />
      </NotifyBlock>

      <div class="body">
        <AppText
          variant="div"
          size="12px"
          mt="40px"
          type="uppercase"
          class="font-medium"
          :line-height="1.3"
        >
          {{ t('merchantBilling.balance.currentPlan') }}
        </AppText>

        <AppText
          v-if="planName"
          variant="div"
          size="24px"
          mt="20px"
          mb="40px"
          :line-height="1.3"
          class="plan-name font-medium"
        >
          {{ t(`plan.${planName}.title`) }}
          <AppIcon
            v-if="planName !== 'free'"
            :key="planName"
            :name="planIcon"
            size="24px"
            is-img-tag
          />
        </AppText>

        <PaymentsInfo
          :plan="planName"
          :count="leftPayments"
          :max="props.paymentsStats?.limit"
          :is-cancelled="isPlanCancelled"
          :days-left="daysLeft"
          :is-lifetime="isLifetimePlan"
        />

        <RenewButton
          v-if="showRenewButton"
          :subscription="props.billingInfo?.subscription"
          :left-payments="leftPayments"
        />

        <AutoRenewButton v-if="isPaidPlan && !isLifetimePlan" :is-cancelled="isPlanCancelled" />

        <div v-if="!isPlanCancelled && !isLifetimePlan" class="buttons">
          <ChangePlanButton
            :billing-info="props.billingInfo"
            :is-plan-cancelled="isPlanCancelled"
          />

          <CancelPlanDropdown
            v-if="isPaidPlan"
            :active-subscriptions="props.activeSubscriptions"
          />
        </div>

        <div class="help-link">
          <ExternalLink
            v-if="!isLifetimePlan"
            :has-prepend="false"
            :href="link"
            is-block
          >
            {{ t('merchantBilling.balance.learnMore') }}
          </ExternalLink>
        </div>
      </div>
    </div>
  </transition>
</template>

<script setup>
import { computed } from 'vue';
import dayjs from 'dayjs';

import ExternalLink from '@/components/ExternalLink.vue';

import { accountTypes } from '@/common/data';

import AutoRenewButton from './components/AutoRenewButton.vue';
import CancelPlanDropdown from './components/CancelPlanDropdown.vue';
import ChangePlanButton from './components/ChangePlanButton.vue';
import NotifyBlock from './components/NotifyBlock.vue';
import PaymentsInfo from './components/PaymentsInfo.vue';
import RenewButton from './components/RenewButton.vue';
import StopCancellationPlanButton from './components/StopCancellationPlanButton.vue';

const props = defineProps({
  billingInfo: {
    type: Object,
    required: true,
  },
  activeSubscriptions: {
    type: Number,
    default: 0,
  },
  paymentsStats: {
    type: Object,
    default: () => ({}),
  },
  isLoaded: {
    type: Boolean,
    default: false,
  },
});

const link = `https://${process.env.VUE_APP_DOCS_URL}/home/fundamentals/plans-and-pricing`;

const currentPlan = computed(() => props.billingInfo?.subscription);
const planName = computed(() => currentPlan.value?.plan?.name?.toLowerCase());
const isPlanCancelled = computed(() => props.billingInfo?.subscription?.plan?.isPlanCancelled);
const planIcon = computed(() => accountTypes[planName.value].icon);

const leftPayments = computed(() => ((props.paymentsStats?.limit || 0) - (props.paymentsStats?.value || 0)));
const isPaidPlan = computed(() => planName.value !== 'free');
const isPlanBlack = computed(() => planName.value === 'black');
const isLifetimePlan = computed(() => props.billingInfo?.subscription?.cycle === 'LIFETIME');

const daysLeft = computed(() => {
  if (props.billingInfo?.subscription?.endDate) {
    return Math.abs(dayjs(Date.now()).diff(props.billingInfo?.subscription?.endDate, 'day'));
  }

  return 'N/A';
});

const showRenewButton = computed(() => (isPaidPlan?.value && !isPlanCancelled?.value && !isPlanBlack?.value));
</script>

<style lang="scss" scoped>
.balance {
  width: 360px;

  .header {
    padding: 0 40px;
    border-bottom: 1px solid var(--color-black-01);
  }

  .body {
    padding: 0 40px;

    :deep(.payments) {
      margin-bottom: 20px;
    }

    :deep(.renew) {
      margin-bottom: 40px;
    }

    :deep(.autorenew) {
      margin: 40px 0 20px;
    }

    .plan-name {
      display: flex;
      align-items: center;
      gap: 14px;
      text-transform: capitalize;
    }

    .buttons {
      display: flex;
      gap: 8px;
    }

    .help-link {
      width: 100%;
      border-top: 1px solid var(--color-black-01);
      margin-top: 30px;
      padding-top: 20px;
    }
  }
}
</style>
