<template>
  <div class="info">
    <div class="left-info">
      <AppText variant="div" opacity="0.5">
        {{ titleLeft }}
      </AppText>
      <AppText
        variant="div"
        size="17px"
        mt="8px"
        opacity="0.5"
        class="font-medium"
      >
        {{ descriptionLeft }}
      </AppText>
    </div>

    <div class="arrows">
      <AppIcon name="arrow-bracket" class="rotate-90" size="18px" opacity="0.1" />
      <AppIcon name="arrow-bracket" class="rotate-90" size="18px" opacity="0.2" />
    </div>

    <div class="right-info">
      <AppText variant="div">
        {{ titleRight }}
      </AppText>
      <AppText
        variant="div"
        size="17px"
        mt="8px"
        class="font-medium"
      >
        {{ descriptionRight }}
      </AppText>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InfoBlock',
  props: {
    titleLeft: {
      type: String,
      default: '',
    },
    descriptionLeft: {
      type: [String, Number],
      default: '',
    },
    titleRight: {
      type: String,
      default: '',
    },
    descriptionRight: {
      type: [String, Number],
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
.info {
  border: 1px solid var(--color-black-01);
  border-radius: 10px;
  padding: 16px 28px;
  display: flex;
  align-items: center;

  .left-info {
    flex-grow: 1;
  }

  .arrows {
    display: flex;
    padding-right: 8px;

    :nth-child(2) {
      margin-left: -10px;
    }
  }

  .right-info {
    width: 50%;
    padding-left: 15px;
  }
}
</style>
