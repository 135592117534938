<template>
  <div class="add-funds-btn" :class="isChecked ? 'close' : 'open'" tabindex="0">
    <AppIcon name="plus" size="18px" />
  </div>
</template>

<script>
export default {
  name: 'AddFundsButton',
  props: {
    isChecked: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.add-funds-btn {
  width: 38px;
  height: 38px;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
  @include flex-center;
  @include transition-base('background-color');

  &:focus-visible {
    @include focus-visible-style;
    border-radius: 50% !important;
  }

  &.open {
    background: #E6E6E6;

    &:hover {
      background: darken(#E6E6E6, 3%);
    }

    :deep(.icon) {
      transition: 0.2s transform;
      transform: rotate(45deg);
    }
  }

  &.close {
    background: var(--color-primary);

    &:hover {
      background: #c26e2c;
    }

    :deep(.icon) {
      transition: 0.2s transform;
      path {
        fill: var(--color-white);
        @include transition-base('fill');
      }
    }
  }
}
</style>
